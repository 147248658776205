@import './colors.scss';

.App {
  height: 100%;
  text-align: center;
}

.page-content {
  position: relative;
  display: flex;
  height: calc(100% - 120px);
}

.esri-widget__heading {
  color: $color-kett-white !important;
  text-transform: uppercase;
}

.esri-widget__heading.esri-legend__service-label {
  color: $color-kett-black !important;
}

.esri-slider__content {
  height: 250px;
}

.esri-slider--vertical {
  width: 32px !important;
}

.esri-legend {
  width: 230px !important;
}

.esri-ui-top-right {
  top: 20px;
  right: 20px;
}

.react-tooltip-lite {
  background: $color-kett-grey-primary;
  color: $color-kett-white;
}

.react-tooltip-lite-arrow {
  border-color: $color-kett-grey-primary;
}
