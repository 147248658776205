@import-normalize "opinionated.css";
@import-sanitize;
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');

html,
body {
  height: 100%;
  overflow: hidden;
}
html {
  font-size: 62.5%;
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 30px;
  line-height: 3rem;
  font-family: 'Lato', sans-serif;
}
button {
  font-family: 'Lato', sans-serif;
}
