$color-kett-white: #fff;
$color-kett-off-white: #cbc4b6;
$color-kett-black: #000;
$color-kett-grey-primary: #484747;
$color-kett-grey-secondary: #74716b;
$color-kett-gold: #e6a100;
$color-kett-green-primary: #408482;
$color-kett-green-secondary: #1e3633;
$color-kett-green-3: #285756;

$color-kett-everything: #000;
$color-kett-people: $color-kett-green-primary;
$color-kett-places: #9a62b5;
$color-kett-stories: #cc6131;
